<template>
    <div style="padding:10px">
        <el-form size="small" :inline="true" v-show="isSearchCollapse" class="query-form" ref="searchForm" :model="searchForm" @keyup.enter.native="refreshList()" @submit.native.prevent>
          <el-form-item prop="loginName">
            <el-input size="small" v-model="searchForm.loginName" placeholder="登录名" clearable></el-input>
          </el-form-item>
          <el-form-item prop="name">
            <el-input size="small" v-model="searchForm.name" placeholder="姓名" clearable></el-input>
          </el-form-item>
        <el-form-item>
          <el-button  type="primary" @click="refresh()" size="small" icon="el-icon-search">查询</el-button>
          <el-button @click="resetSearch()" size="small" icon="el-icon-refresh-right">重置</el-button>
        </el-form-item>
        </el-form>

        <el-row>
          <el-button v-if="hasPermission('sys:role:assign')" type="primary" size="small" icon="el-icon-plus" @click="add()">添加用户</el-button>
          <el-button-group class="f_r">
            <el-tooltip class="item" effect="dark" content="搜索" placement="top">
              <el-button
                type="default"
                size="small"
                icon="el-icon-search"
                @click="isSearchCollapse = !isSearchCollapse, isImportCollapse=false">
              </el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="刷新" placement="top">
              <el-button
                type="default"
                size="small"
                icon="el-icon-refresh"
                @click="refreshList">
              </el-button>
            </el-tooltip>
          </el-button-group>
        </el-row>
        <div style="height: calc(100vh - 200px)">
          <el-table
                  :data="dataList"
                  v-loading="loading"
                  height="100%"
                  @render-header ="elTableHeadFunction"
                  size = "small"
                  @selection-change="selectionChangeHandle"
                  @sort-change="sortChangeHandle"
                  class="table">
            <el-table-column
                    type="selection"
                    header-align="center"
                    align="center"
                    width="50">
            </el-table-column>
            <el-table-column
                    prop="image"
                    header-align="center"
                    align="center"
                    label="头像">
              <template slot-scope="scope">
                <el-image class="infoImg" :src="scope.row.image" fit="contain">
                  <el-image slot="placeholder" fit="contain" class="infoImg"
                            :src="require('../../../../assets/img/defaultAvatar.png')"></el-image>
                  <el-image slot="error" fit="contain" class="infoImg"
                            :src="require('../../../../assets/img/defaultAvatar.png')"></el-image>
                </el-image>
              </template>
            </el-table-column>
            <el-table-column
                    prop="mobilePhone"
                    sortable="custom"
                    min-width="100px"
                    label="登录名">
            </el-table-column>
            <el-table-column
                    prop="name"
                    min-width="100px"
                    sortable="custom"
                    label="用户名">
            </el-table-column>
            <el-table-column
                    prop="email"
                    sortable="custom"
                    min-width="100px"
                    show-overflow-tooltip
                    label="邮箱">
            </el-table-column>
            <el-table-column
                    prop="mobilePhone"
                    sortable="custom"
                    show-overflow-tooltip
                    min-width="100px"
                    label="手机号">
            </el-table-column>
            <el-table-column
                    prop="logicType"
                    min-width="100px"
                    label="状态">
              <template slot-scope="scope">
                <el-tag v-if="scope.row.logicType === 0" size="small" type="success">启用</el-tag>
                <el-tag v-else-if="scope.row.logicType === 1" size="small" type="danger">未启用</el-tag>
              </template>
            </el-table-column>
            <el-table-column
                    fixed="right"
                    :key="Math.random()"
                    header-align="center"
                    align="center"
                    width="100"
                    label="操作">
              <template slot-scope="scope">
                <el-button v-if="hasPermission('sys:role:assign')" type="text" size="small" @click="del(scope.row.id)">
                  移除
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination
          @size-change="sizeChangeHandle"
          @current-change="currentChangeHandle"
          :current-page="pageNo"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="pageSize"
          :total="total"
          background
          layout="total, sizes, prev, pager, next, jumper">
        </el-pagination>
      <user-select ref="userSelect" @doSubmit="selectUsersToRole"></user-select>
  </div>
</template>

<script>
  import UserSelect from '@/components/userSelect/UserSelectDialog'
  export default {
    data () {
      return {
        searchForm: {
          loginName: '',
          name: ''
        },
        dataList: [],
        pageNo: 1,
        pageSize: 10,
        total: 0,
        orderBy: '',
        dataListSelections: [],
        isSearchCollapse: false,
        isImportCollapse: false,
        loading: false
      }
    },
    components: {
      UserSelect
    },
    props: ['roleUserTitle', 'roleId'],
    activated () {
      this.refreshList()
    },
    methods: {
      elTableHeadFunction (h, l, fontSize) {
        let f = 14
        if (typeof (fontSize) !== 'undefined' && fontSize != null) {
          f = fontSize
        }
// 列头的实际宽度
        let width = l.column.realWidth
// 14：字体大小 32 是el表格的左右 padding 的合
        let maxFontCount = Math.floor((width - 32) / f) - 1
        let chars = l.column.label.split('')
        var label = ''
        if (maxFontCount < chars.length) {
          for (let i = 0; i < maxFontCount; i++) {
            label += chars[i]
          }
          label += '..'
        } else {
          label = l.column.label
        }
        return label
      },
      refresh(){
        this.currentChangeHandle(1)
      },
      // 获取数据列表
      refreshList () {
        this.loading = true
        this.$axios(this.api.auth.getByIdAllUser, {
          'current': this.pageNo,
          'size': this.pageSize,
          'orderBy': this.orderBy,
          'roleId': this.roleId,
          ...this.searchForm
        }, 'get').then(data => {
          console.log(data);
          if (data && data.status) {
            this.dataList = data.data.records
            this.total = parseInt(data.data.total)
            this.loading = false
          }
        })
      },
      // 每页数
      sizeChangeHandle (val) {
        this.pageSize = val
        this.pageNo = 1
        this.refreshList()
      },
      // 当前页
      currentChangeHandle (val) {
        this.pageNo = val
        this.refreshList()
      },
      // 多选
      selectionChangeHandle (val) {
        this.dataListSelections = val
      },
       // 排序
      sortChangeHandle (obj) {
        if (obj.prop === 'office.name') {
          obj.prop = 'o.name'
        }
        if (obj.prop === 'company.name') {
          obj.prop = 'c.name'
        }
        if (obj.order === 'ascending') {
          this.orderBy = obj.prop + ' asc'
        } else if (obj.order === 'descending') {
          this.orderBy = obj.prop + ' desc'
        } else {
          this.orderBy = ''
        }
        this.refreshList()
      },
      // 新增
      add () {
        this.$refs.userSelect.init(this.roleId)
      },

      // 删除
      del (id) {
        this.$confirm(`确认把用户从角色中移除吗?`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.loading = true
          this.$axios(this.api.auth.removeById, {userId: id, roleId: this.roleId}, 'delete').then(data => {
            this.loading = false
            if (data && data.status) {
              this.$message.success({dangerouslyUseHTMLString: true,
                message: data.msg})
              this.refreshList()
            }
          })
        })
      },
      closeRight () {
        this.$emit('closeRight')
      },
      resetSearch () {
        this.$refs.searchForm.resetFields()
        this.currentChangeHandle(1)
      },
      selectUsersToRole (users) {
        let ids = users.map(user => { return user.id }).join(',')
        this.loading = true
        console.log(this.roleId);
        console.log(ids);
        this.$axios(this.api.auth.sysuserroleSave, {roleId: this.roleId, userIds: ids}, 'post').then(data => {
          this.loading = false
          if (data && data.status) {
            this.$message.success(data.msg)
            this.refreshList()
          }
        })
      }
    }
  }
</script>
<style scoped>
  .infoImg {
    width: 35px;
  }
</style>
